@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
}

div,
div:before,
div:after {
	box-sizing: inherit;
}

