.box {
    background-color: #0C0C17;
    display: flex;
    align-items: center;
    width: 60px;
    height: 60px;
    justify-content: center;
}

.box-container {
    border: 1px solid #0C0C17;
    padding: 30px;
}

.heading {
    color: #C2C6C8;
    font-size: 30px;
    font-weight: 600;
}

input[type='text'], input[type='date'], select {
    background-color: #0C0C17;
    display: block;
    margin-top: 10px;
    width: 100%;
}

label {
    color: #282A36;
    padding-bottom: 5px;
}

.modal {
    background-color: #020311E5;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #282A36;
    text-align: center;
}
.modalContainer {
    border: 1px solid #131324;
    padding: 20px 50px 50px;
    border-radius: 10px;
}

.subheading-light {
    color: #C2C6C8;
    font-size: 18px;
}
.subheading-dark {
    color: #282A36;
    font-size: 14px;;
}